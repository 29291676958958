
import Vue from 'vue'
import Component from 'vue-class-component'
import { stringUtils } from '@/utils/string'

import EmptyStateCard from '@/views/components/EmptyStateCard.vue'
import RequestLeasObjectsTable from '@/views/RequestLeasObjects/components/RequestLeasObjectsTable.vue'
import DealershipAdvantagesModal
  from '@/views/RequestLeasObjects/RequestLeasObcjectsDetails/components/DealershipAdvantagesModal.vue'
import { Watch } from 'vue-property-decorator'
import CarSearchRequest from '@/apis/CarSearchRequest'
import Logs from '@/apis/Logs'
// import DsTelegramModal from '@/views/components/DsTelegramModal'

@Component({
  name: 'RequestLeasObjectsList',
  components: {
    EmptyStateCard,
    RequestLeasObjectsTable,
    DealershipAdvantagesModal
    // DsTelegramModal
  }
})

export default class RequestLeasObjectsList extends Vue {
  @Watch('pagination.currentPage')
  onPaginationChange(val) {
    this.getRequestList(val, this.perPage)
  }

  @Watch('search')
  onSearchChange() {
    this.getRequestList(1, this.perPage)
  }

  searchLeasingRequests = {
    headers: [
      {
        text: 'Номер',
        sortable: false,
        value: 'id'
      },
      {
        text: 'Дата запроса',
        sortable: false,
        value: 'date'
      },
      {
        text: 'Транспортное средство',
        sortable: false,
        value: 'car'
      },
      {
        text: 'Лизинговая компания',
        sortable: false,
        value: 'leasingCompany'
      },
      {
        text: 'Статус',
        sortable: false,
        value: 'status'
      },
      {
        text: '',
        sortable: false,
        value: 'options'
      }
    ],
    items: []
  }

  pagination = {
    total: null,
    currentPage: null,
    firstPage: null,
    lastPage: null,
    firstPageUrl: null,
    lastPageUrl: null,
    nextPageUrl: null,
    perPage: null
  }

  perPage = 10

  countOfRequests(number: number): string {
    return stringUtils.pluralForm(number, ['запрос', 'запроса', 'запросов'])
  }

  created() {
    // @ts-ignore
    this.$setLoading(true)
    this.getRequestList(1, this.perPage)
    this.sendLogs()
    // @ts-ignore
    this.$socket.on('notification', (data) => {
      if (this.$route.name === 'RequestLeasObjectsList') {
        if (data?.type === 'new_request') this.getRequestList(this.pagination.currentPage, this.perPage)
      }
    })
  }

  sendLogs() {
    Logs.requestLeasObjects()
  }

  getRequestList(page, perPage) {
    CarSearchRequest.getRequestList(page, perPage, this.search)
      .then(response => {
        this.searchLeasingRequests.items = Object.values(response.data.data)
        this.pagination = this.fillPagination(response.data)
      })
      .catch(() => {
        // this.showErrorSystemNotification('Что-то пошло не так!')
      })
      .finally(() => {
        // @ts-ignore
        this.$setLoading(false)
      })
  }

  fillPagination(data) {
    return {
      total: data.total,
      currentPage: data.current_page,
      firstPage: data.first_page ?? null,
      lastPage: data.last_page ?? null,
      firstPageUrl: data.first_page_url ?? null,
      lastPageUrl: data.last_page_url ?? null,
      nextPageUrl: data.next_page_url ?? null,
      perPage: data.per_page ?? 10
    }
  }

  get search() {
    return this.$store.state.common.searchDsRequests
  }

  set search(item) {
    this.$store.state.common.searchDsRequests = item
  }

  get showModal() {
    if (localStorage.getItem('showModalSearchDsRequests')) return false
    localStorage.setItem('showModalSearchDsRequests', '1')
    return this.$store.state.common.showModalSearchDsRequests
  }

  set showModal(item) {
    this.$store.state.common.showModalSearchDsRequests = item
  }

  mounted() {
    if (this.$route.params?.sended) {
      // @ts-ignore
      this.showSuccessSystemNotification('Запрос успешно отправлен')
    }
  }
}


import Vue from 'vue'
import Component from 'vue-class-component'
// import Axios from 'axios'
import { PropSync } from 'vue-property-decorator'

@Component({
  name: 'DealershipAdvantagesModal'
})

export default class DealershipAdvantagesModal extends Vue {
  @PropSync('showModal', { type: Boolean }) syncShowModal!: boolean
}
